import logo from './logo6new.png';
import videobryan from './videos/6stem.com.mp4';
import React, { Component } from 'react';

/*
import Header from './components/Header';
import SideBar from './components/SideBar';
import Content from './components/Content';
*/
import './App.css';

/*
      <div>

        <Header />
        <SideBar />
        <Content />

      </div>    


      <a href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x7F44a04175983FbeEbE5Bb0083C36F41e88c244B" target="_blank">
      <code><b> Blochain</b></code>
        </a>
        <a href="https://game-zone.6stem.com/" target="_blank">
      <code><b> Game-zone</b></code>
        </a>
        <a href="https://web-app.6stem.com/" target="_blank">
      <code><b> Web-App</b></code>
        </a>                
*/
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
           
          <img src={logo} className="App-logo" alt="logo" />
          <code>&nbsp;<b><big><big><big><big>IA INFORMATIQUE</big></big></big></big></b></code><br></br>
          <code>&nbsp;<b>SITE WEB - PUB - FLYER - WEB GAME - ROBOT INFORMATIQUE</b></code><br></br>
          <code>&nbsp;(Devis Gratuit)</code><br></br>
           
        </p>
        <video 
          height="100%" 
          width="100%"
          src={videobryan}
          autoPlay={true}
          loop={true}
          muted={true}
          controls={true}
        />  
        <br></br><br></br><br></br>
        <code className="App"><b>&nbsp;6stem.com :  Numeric System Automation </b><br></br>
<br></br><br></br><br></br>
Des solutions informatiques intelligentes pour une performance optimisée !<br></br> 
Vous voulez libérer du temps pour vous concentrer sur ce qui compte vraiment ?<br></br>
<br></br>
Laissez faire nos robots !<br></br>
Automatisez vos tâches informatiques et optimisez la gestion de votre entreprise grâce à nos solutions de robotisation et automatisation intelligente.<br></br>
<br></br>
Nos solutions automatisées gèrent tout pour vous :<br></br>
</code>
<code className="App-left"><br></br>          
• Traitement des données<br></br>
• Tâches répétitives<br></br>
• Automatisation des processus métier<br></br>
</code>
<code className="App">
<br></br>
Création de sites internet professionnels<br></br>
Vous avez besoin d'une présence en ligne forte et professionnelle ? Nous concevons pour vous des sites internet modernes, réactifs et optimisés pour tous les supports. Offrez à vos clients une expérience web unique !<br></br>
<br></br>
Applications web sur mesure<br></br>
Créez des applications web puissantes et personnalisées, qui répondent aux besoins spécifiques de votre activité. Nous transformons vos idées en outils performants pour votre entreprise.<br></br>
<br></br>
Des jeux en ligne qui captivent !<br></br>
Vous avez un projet de jeu en ligne ? Nous développons des jeux interactifs et engageants, à la pointe de la technologie, pour divertir et fidéliser votre audience.<br></br>
Pourquoi nous choisir ?<br></br>
</code>
<code className="App-left"><br></br>
• Expertise reconnue en solutions informatiques<br></br>
• Équipe passionnée et à l’écoute<br></br>
• Réalisations sur mesure<br></br>
• Résultats rapides et efficaces<br></br>
<br></br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contactez-nous dès aujourd'hui!<br></br><br></br>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Société 6stem.com<br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6STEM.COM, société à responsabilité limitée, immatriculée sous le SIREN 897913927<br></br><br></br><br></br>
Téléphone : &nbsp;&nbsp;&nbsp;&nbsp;Dans la vidéo<br></br>
Email : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dans la vidéo<br></br>
Site internet : 6stem.com<br></br>
          Adresse : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dans la vidéo     <br></br> <br></br> <br></br><br></br>
</code>
      </header>

    </div>
  );
}

export default App;